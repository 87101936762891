import StringFilter from "../Filters/StringFilter";
import BoolFilter from "../Filters/BoolFilter";
import DateFilter from "../Filters/DateFilter";
import IntegerFilter from "../Filters/IntegerFilter";

export const RenderFilter = (type, columnName, setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
) => {
    switch (type) {
        case 'Numeric':
            return (
                <IntegerFilter
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    placeholder={`${columnName}`}
                ></IntegerFilter>
            );
            break;
        case 'DateTime':
            return (
                <DateFilter
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                ></DateFilter>
            )
            break;
        case 'String':
            return (
                <StringFilter
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                    placeholder={`Filter by ${columnName}`}
                ></StringFilter>
            );
            break;
        case 'Bool':
            return (
                <BoolFilter
                    setSelectedKeys={setSelectedKeys}
                    selectedKeys={selectedKeys}
                    confirm={confirm}
                    clearFilters={clearFilters}
                ></BoolFilter>
            )
            break;
        default:
            return (<></>);
            break;
    }
}