import { AmazonIcon, CsvIcon, DropboxIcon, ExcelIcon, JsonIcon, EmailIcon, GoogleIcon, FtpIcon } from "./Icons/DestinationIcons";

export const RenderDestinationFormat = ({ format, height, width, style }) => {
    switch (format) {
        case 'CSV':
            return <CsvIcon style={style} height={height} width={width}></CsvIcon>
        case 'Excel':
            return <ExcelIcon style={style} height={height} width={width}></ExcelIcon>
        case 'JSON':
            return <JsonIcon style={style} height={height} width={width}></JsonIcon>
        default:
            break;
    }
}

export const RenderDestinationType = ({ type, height, width, style }) => {
    switch (type) {
        case 'Email':
            return <EmailIcon style={style} height={height} width={width}></EmailIcon>
        case 'DropBox':
            return <DropboxIcon style={style} height={height} width={width}></DropboxIcon>
        case 'GoogleDrive':
            return <GoogleIcon style={style} height={height} width={width}></GoogleIcon>
        case 'Amazon':
            return <AmazonIcon style={style} height={height} width={width}></AmazonIcon>
        case 'FTP':
            return <FtpIcon style={style} height={height} width={width}></FtpIcon>
        default:
            break;
    }
}