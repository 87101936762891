import { Button, List } from "antd";
import ExportsList from "./ExportsList";
import { useNavigate } from 'react-router-dom';

const MainPage = () => {

    let navigate = useNavigate();

    const CreateExport = () => {
        navigate("/export")
    }

    return (<>
        <div style={{ display: "flex", flexDirection: "row", marginBottom: "10px", marginTop: "10px" }}>
            <Button onClick={CreateExport} type="primary">Create New</Button>
        </div>
        <ExportsList onCreate={CreateExport}></ExportsList>
    </>);
}

export default MainPage;