import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import undoable from 'redux-undo';
import { getExport } from '../../utils/api';

// Define the initial state
const initialState = {
    id: null,
    name: '',
    description: '',
    type: 'ProcessedOrders',
    columns: [],
    filters: [],
    sorters: [],
    destination: {
        format: '',
        type: '',
        enableSsl: false
        // ftpMode: 'ftp://'
    },
    cron: '0 0 * * *',
    isLoading: false,
    error: null,
    currentPanel: "Basic Settings"
};

// Define the thunk action to fetch an Exp by ID
export const setExpById = createAsyncThunk('exp/fetchById', async (id, { rejectWithValue }) => {
    try {
        const response = await getExport(id);
        return response;
    } catch (error) {
        return rejectWithValue(error.response.data);
    }
});

// Define the slice with the undoable higher-order reducer
const expSlice = createSlice({
    name: 'exp',
    initialState,
    reducers: {
        updateName: (state, action) => {
            state.name = action.payload;
        },
        updateDescription: (state, action) => {
            state.description = action.payload;
        },
        setInitial: (state, action) => {
            return initialState;
        },
        updateType: (state, action) => {
            state.type = action.payload;
        },
        updateCron: (state, action) => {
            state.cron = action.payload;
        },
        updateColumns: (state, action) => {
            state.columns = action.payload.map((c, i) => { return { ...c, order: i + 1 } });
        },
        removeColumn: (state, action) => {
            state.columns = state.columns.filter(c => c.propertyPath !== action.payload)
            state.filters = state.filters.filter(f => f.field !== action.payload)
            state.sorters = state.sorters.filter(s => s.columnKey !== action.payload)
        },
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updateSorters: (state, action) => {
            state.sorters = action.payload;
        },
        updateDestination: (state, action) => {
            state.destination = action.payload;
        },
        updatePanel: (state, action) => {
            state.currentPanel = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(setExpById.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(setExpById.fulfilled, (state, action) => {

                state.isLoading = false;
                state.currentPanel = "Basic Settings";
                state.error = null;
                state.id = action.payload.id;
                state.name = action.payload.name;
                state.description = action.payload.description;
                state.type = action.payload.type;
                state.columns = action.payload.columns;
                state.filters = action.payload.filters;
                state.sorters = action.payload.sorters;
                state.cron = action.payload.cron;
                state.destination = action.payload.destination;
            })
            .addCase(setExpById.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload ? action.payload.message : action.error.message;
            });
    }
});

// Export the actions and reducer with undoable
// export const { undo, redo } = undoable(expSlice.actions, {
//     filter: (action) => action.type !== setExpById.fulfilled.type && action.payload?.id !== undefined
// });

export const {
    updateId,
    updateName,
    updateDescription,
    updateType,
    updateColumns,
    updateFilters,
    updateSorters,
    updateDestination,
    updatePanel,
    removeColumn,
    setInitial,
    updateCron
} = expSlice.actions;

export default undoable(expSlice.reducer, {
    filter: (action) => {

        // console.log("FILTERING ACTION")
        // console.log(action)

        if (action.type.includes('authorization')) {
            console.log("RETURNED FALSE")
            return false;
        }
        if (action.type.includes('setInitial')) {
            console.log("RETURNED FALSE")
            return false;
        }
        if (action.type.includes('updateName') && action?.payload?.name == "") {
            console.log("RETURNED FALSE")
            return false;
        }

        if (action.type === setExpById.pending.type) {
            console.log("RETURNED FALSE")
            return false;
        }
        if (action.type === setExpById.fulfilled.type) {
            console.log("RETURNED FALSE")
            return false;
        }

        if (action?.payload?.hasOwnProperty('id') && (action?.payload?.id === undefined || action?.payload?.id === '')) {
            console.log("RETURNED FALSE")
            return false;
        }

        console.log("RETURNED TRUE ")
        return true;
    },
    // updateFilter: (newState, prevState, history) => {
    //     console.log("newState")
    //     console.log(newState)
    //     console.log("prevState")
    //     console.log(prevState)
    //     console.log("history")
    //     console.log(history)
    //     return newState;
    // },
});


