import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import axios from 'axios';
import { authorize } from '../../utils/endpoints'
import setDefaultHeader from '../../utils/setDefaultHeader';


const initialState = {
    isAuthenticated: false,
    isAdmin: false,
    isLoading: false,
    session: {}
};

export const login = createAsyncThunk(
    'authorization/login',
    async (token, thunkAPI) => {
        try {
            const resp = await axios.post(authorize, { ApplicationToken: token, });
            return resp.data;
        } catch (error) {
            return thunkAPI.rejectWithValue('something went wrong');
        }
    }
);

const authorizationSlice = createSlice({
    name: 'authorization',
    initialState,
    reducers: {
        clearAuthorization: (state) => {
            state = { ...initialState };
        },
        setAuthorization: (state, { payload }) => {
            state.isAuthenticated = !isEmpty(payload.session);
            state.isLoading = false;
            state.session = payload.session;
            state.isAdmin = payload.isAdmin;
        },
    },
    extraReducers: {
        [login.pending]: (state) => {
            state.isLoading = true;
        },
        [login.fulfilled]: (state, action) => {
            state.isLoading = false;
            setDefaultHeader('Authorization', action.payload.session.token);
            setDefaultHeader('Server', action.payload.session.server);
            state.isAuthenticated = !isEmpty(action.payload.session);
            state.session = action.payload.session;
            state.isAdmin = action.payload.isAdmin;
        },
        [login.rejected]: (state) => {
            state = { ...initialState };
        },
    }
});

export const { clearAuthorization, setAuthorization } =
    authorizationSlice.actions;

export default authorizationSlice.reducer;