import styled from 'styled-components'
import { FiletypeCsv, FileEarmarkExcel, FiletypeJson } from '@styled-icons/bootstrap'
import { Email } from '@styled-icons/material-outlined'
import { Dropbox, Amazon, GoogleDrive } from '@styled-icons/fa-brands'
import { Server } from '@styled-icons/boxicons-regular'

export const CsvIcon = styled(FiletypeCsv)`
    color: #0092E4;`
export const ExcelIcon = styled(FileEarmarkExcel)`
    color: #0092E4;`
export const JsonIcon = styled(FiletypeJson)`
    color: #0092E4;`
export const EmailIcon = styled(Email)`
    color: #0092E4;`
export const DropboxIcon = styled(Dropbox)`
    color: #0092E4;`
export const AmazonIcon = styled(Amazon)`
    color: #0092E4;`
export const FtpIcon = styled(Server)`
    color: #0092E4;`
export const GoogleIcon = styled(GoogleDrive)`
    color: #0092E4;`