import { useEffect, useState } from "react";
import axios from 'axios';
import { List, Button, Result, Empty, Space, Table, ConfigProvider, Modal } from 'antd';
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deleteExport, getExports, runExport } from "../../utils/api"
import { CaretRightOutlined, DeleteOutlined, EditOutlined, ExclamationCircleOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { UilFolderOpen, UilClock, UilStopwatch, UilArrowRight } from '@iconscout/react-unicons'
import Column from "antd/es/table/Column";
import { flipActiveExport } from "../../utils/endpoints";
import { useNavigate } from "react-router-dom";
import useNotification from "antd/es/notification/useNotification";
import { RenderDestinationFormat, RenderDestinationType } from "../IconRenders";

const { Item } = List;
const { confirm } = Modal;


const ExportsList = ({ onCreate }) => {
    let navigate = useNavigate();
    const queryClient = useQueryClient();

    const [api, contextHolder] = useNotification();
    const openNotification = (title, message, type) => {
        api.open({
            type: type,
            message: title,
            description: <div>{message}</div>,
            placement: 'bottomRight'
        });
    };

    const [selectedExport, setSelectedExport] = useState();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleOk = async () => {
        setLoading(true);

        await runExport(selectedExport?.id, false);

        setLoading(false);
        setShowModal(false);
        setSelectedExport(undefined);

        openNotification('Success', 'Export is generating', 'success');
    };

    const handleCancel = () => {
        setLoading(false);
        setSelectedExport(undefined);
        setShowModal(false);
    };

    const { isError, isSuccess, isLoading, data, error } = useQuery(
        ["exports"],
        getExports,
        { staleTime: 10 * 60 * 100 }
    );
    useEffect(() => {
        queryClient.invalidateQueries();
    }, [])

    const FlipActive = useMutation({
        mutationFn: (ex) =>
            axios
                .post(flipActiveExport(ex.id, !ex.isActive))
                .then((response) => response.data),
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['exports'] })

            // Snapshot the previous value
            const previousExports = queryClient.getQueryData(['exports'])

            // Optimistically update to the new value
            queryClient.setQueryData(['exports'], (old) => {
                return old.map(e => e.id === ex.id ? { ...e, isActive: !e.isActive } : e);
            });

            // Return a context object with the snapshotted value
            return { previousExports }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['exports'], context.previousExports)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['exports'] })
        },
    })

    const DeleteExport = useMutation({
        mutationFn: deleteExport,
        // When mutate is called:
        onMutate: async (ex) => {
            // Cancel any outgoing refetches
            // (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: ['exports'] })

            // Snapshot the previous value
            const previousExports = queryClient.getQueryData(['exports'])

            // Optimistically update to the new value
            queryClient.setQueryData(['exports'], (old) => {
                return old.filter(e => e.id !== ex.id);
            });

            // Return a context object with the snapshotted value
            return { previousExports }
        },
        // If the mutation fails,
        // use the context returned from onMutate to roll back
        onError: (err, newTodo, context) => {
            queryClient.setQueryData(['exports'], context.previousExports)
        },
        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: ['exports'] })
        },
    })

    const HorizontalCell = ({ children }) => (<div style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
    }}>
        {children}
    </div>);
    const VerticalCell = ({ children }) => (<div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        flexDirection: "column",
    }}>
        {children}
    </div>);

    return (<>
        {contextHolder}
        <ConfigProvider renderEmpty={() => <Empty style={{ marginTop: "80px" }}
            description={
                <span>
                    You have no exports yet
                </span>
            }
        >
            <Button type="primary" onClick={onCreate}>Create Now</Button>
        </Empty>}>
            <Modal
                open={showModal}
                title="Are you sure?"
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Back
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                        Run without time affecting
                    </Button>,
                    <Button
                        key="link"
                        type="primary"
                        danger
                        loading={loading}
                        onClick={async () => {
                            setLoading(true);

                            await runExport(selectedExport?.id, true);

                            setLoading(false);
                            setShowModal(false);
                            setSelectedExport(undefined);

                            openNotification('Success', 'Export is generating', 'success');
                        }}
                    >
                        Run affecting time
                    </Button>,
                ]}
            >
                {/* <p>{selectedExport?.id}</p> */}
                This will affect the export recorded run time, which may disrupt its filters, so we recommend you run it without time affecting unless you are sure you want to do otherwise.
            </Modal>
            <Table dataSource={data} pagination={false} loading={isLoading}>
                <Column title="Type" dataIndex="type" key="type" width={200}
                    render={(value) => {
                        return (
                            <HorizontalCell>
                                <div style={{ width: "35px" }}>
                                    <UilFolderOpen color="#0092E4" size="35"></UilFolderOpen>
                                </div>
                                <div style={{ marginLeft: "3px" }}>{value}</div>
                            </HorizontalCell>
                        )
                    }} />
                <Column title="Name" dataIndex="name" key="name" />
                {/* <ColumnGroup key="dest" title="Destination"> */}
                <Column title="File Type" dataIndex="destination" key="format" width={100}
                    render={(value) => {
                        return (
                            <VerticalCell>
                                <RenderDestinationFormat format={value.format} height="35px" width="35px" style={{ marginBottom: "3px" }}></RenderDestinationFormat>
                                {value.format}
                            </VerticalCell>
                        )
                    }} />
                <Column title="To" dataIndex="destination" key="to" width={50}
                    render={(value) => {
                        return (
                            <VerticalCell>
                                <UilArrowRight color="#0092E4" size="35"></UilArrowRight>
                            </VerticalCell>
                        )
                    }} />
                <Column title="Destination" dataIndex="destination" key="type" width={110}
                    render={(value) => {
                        return (
                            <VerticalCell>
                                <RenderDestinationType type={value.type} height="35px" width="35px" style={{ marginBottom: "3px" }}></RenderDestinationType>
                                {value.type}
                            </VerticalCell>
                        )
                    }} />
                {/* </ColumnGroup> */}
                <Column title="Last Generated" dataIndex="lastGenerated" key="lastGenerated" width={200}
                    render={(value) => {
                        return (
                            <HorizontalCell>
                                <div style={{ width: "35px" }}>
                                    <UilClock color="#0092E4" size="35"></UilClock>
                                </div>
                                <div style={{ marginLeft: "3px" }}>{value}</div>
                            </HorizontalCell>
                        )
                    }}
                />
                <Column title="Next Generation" dataIndex="nextGenerating" key="nextGenerating"
                    width={300}
                    render={(value) => {
                        return (
                            <HorizontalCell>
                                <div style={{ width: "35px" }}>
                                    <UilStopwatch color="#0092E4" size="35"></UilStopwatch>
                                </div>
                                <div style={{ marginLeft: "3px" }}>{value}</div>
                            </HorizontalCell>
                        )
                    }}
                />

                <Column
                    title="Actions"
                    key="action"
                    render={(text, record) => (
                        <Space size="small">
                            <Button type="primary" style={{ width: "130px" }}
                                icon={<CaretRightOutlined />}
                                onClick={
                                    () => {
                                        setSelectedExport(record);
                                        setShowModal(true);
                                    }}>
                                Run Now
                            </Button>

                            <Button type="primary"
                                icon={
                                    record.isActive ?
                                        <><PauseCircleOutlined /></>
                                        :
                                        <PlayCircleOutlined />
                                }
                                onClick={(e) => FlipActive.mutate(record)}>
                                {
                                    record.isActive ?
                                        <>Pause</>
                                        :
                                        <>Activate</>
                                }
                            </Button>
                            <Button type="primary"
                                icon={
                                    <EditOutlined />
                                }
                                onClick={(e) => navigate(`export/${record.id}`)}>
                                Edit
                            </Button>
                            <Button type="primary" danger style={{ width: "100px" }}
                                icon={<DeleteOutlined />}
                                onClick={() => {
                                    confirm({
                                        title: 'Please confirm you want to delete this export.',
                                        icon: <ExclamationCircleOutlined />,
                                        content: 'This export will be deleted permanently. If you want to stop it from running you can just pause it.',
                                        okText: 'Yes, i want to delete this export',
                                        okType: 'danger',
                                        cancelText: 'Back',

                                        onOk() {
                                            DeleteExport.mutate(record);
                                        },
                                        onCancel() { },
                                    });
                                }}>
                                Delete
                            </Button>
                        </Space>
                    )}
                />

            </Table>
            {isError && <Result
                status="warning"
                title="Can't download exports. Please, try later."
            />}
        </ConfigProvider >
    </>);
}

export default ExportsList;