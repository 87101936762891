/* eslint-disable react/prop-types */
import { Button, Input, Divider, Switch, Tooltip } from 'antd';
import '@vscode/codicons/dist/codicon.css';
import React from "react";


const StringFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder }) => {

    return (<div style={{ padding: "4px" }}>
        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Input
                autoFocus
                placeholder={placeholder}
                value={selectedKeys[0]?.search}
                onChange={(e) => {
                    setSelectedKeys(e.target.value ? [{ ...selectedKeys[0], search: e.target.value }] : []);
                }}
                onPressEnter={() => {
                    confirm();
                }}
                onBlur={() => {
                    console.log("BLUR CONFIRM")
                    // confirm();
                }}
                style={{ flex: 1, marginRight: "3px" }}
            ></Input>

            <Tooltip placement="bottom" title="Case Sensitive">
                <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-case-sensitive'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-case-sensitive'></div>} checked={selectedKeys[0]?.isCaseSensitive} onChange={(value) => {
                    setSelectedKeys([{ ...selectedKeys[0], isCaseSensitive: value }]);
                }} />
            </Tooltip>

            <Tooltip placement="bottom" title="Exact Search">
                <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-whole-word'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-whole-word'></div>} checked={selectedKeys[0]?.isWholeWord} onChange={(value) => {
                    setSelectedKeys([{ ...selectedKeys[0], isWholeWord: value }]);
                }} />
            </Tooltip>

            <Tooltip placement="bottom" title="Advanced filtering language - Regex. You might need an IT specialist to help you with that." >
                <Switch checkedChildren={<div style={{ marginTop: "3px" }} class='codicon codicon-regex'></div>} unCheckedChildren={<div style={{ marginTop: "1px" }} class='codicon codicon-regex'></div>} checked={selectedKeys[0]?.isRegex} onChange={(value) => {
                    setSelectedKeys([{ ...selectedKeys[0], isRegex: value }]);
                }} />
            </Tooltip>
        </div>

        <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
        <Button
            onClick={() => {
                confirm();
            }}
            type="primary"
        >
            Search
        </Button>
        <Button
            onClick={() => {
                clearFilters();
                confirm();
            }}
            type="danger"
        >
            Reset
        </Button>
    </div>);
}

export default StringFilter;