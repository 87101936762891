import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { useDispatch } from "react-redux";
import setDefaultHeader from '../../utils/setDefaultHeader';
import { Spin } from 'antd';
import { login } from '../../data/authorization/authorizationSlice'

const Authenticate = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        let token = window.location.search.substring(
            window.location.search.lastIndexOf("?token=") + "?token=".length,
            window.location.search.lastIndexOf("&userName=")
        );
        let email = window.location.search.substring(
            window.location.search.lastIndexOf("&userName=") + "&userName=".length,
            window.location.search.lastIndexOf("&sessionUserId=")
        );
        if (isEmpty(token)) {
            token = '00000000-0000-0000-0000-000000000000';
        }
        setDefaultHeader('Authorization', token);
        setDefaultHeader('Email', email);
        dispatch(login(token));
    }, [])

    return <Spin tip="Authenticating..." className='absolute-centered' />;
}


export default Authenticate;
