import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAllS3Locations, getColumns, getExportPreview, saveExport } from "../../utils/api";
import { CheckSquareOutlined, CloseOutlined, CloudServerOutlined, EditOutlined, FolderOpenOutlined, KeyOutlined, LeftOutlined, LockOutlined, LoginOutlined, RightOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Cron } from 'react-js-cron'; //https://github.com/xrutayisire/react-js-cron
import 'react-js-cron/dist/styles.css'
import '../../index.css';
import useNotification from "antd/es/notification/useNotification";
import { Collapse, Input, Select, Space, Button, Table, Modal, Popconfirm, Switch, InputNumber, Spin, Form, Typography, Empty } from 'antd';
import ReactDragListView from 'react-drag-listview';
import {
    setExpById,
    updateName,
    updateDescription,
    updateType,
    updateColumns,
    updateFilters,
    updateSorters,
    updateDestination,
    updatePanel,
    removeColumn,
    setInitial,
    updateCron
} from '../../data/ExportEdit/exportEditSlice';
import { ActionCreators } from "redux-undo";

import { RenderDestinationFormat, RenderDestinationType } from "../IconRenders";
import { RenderFilter } from "./RenderFilter";
import CustomSelect from "./CustomSelect";
import EditingStage from "./EditingStage";
const { Panel } = Collapse;
const { Title } = Typography;
const { Column } = Table;
const { TextArea } = Input;
const { Option } = Select;


const Create = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    let { id } = useParams();
    const [destinationForm] = Form.useForm();
    const [basicSettingsForm] = Form.useForm();
    const [cronError, onCronError] = useState()
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(false)

    const exp = useSelector((state) => state.exp.present);
    const canUndo = useSelector(state => state.exp.past.length > 0);
    const canRedo = useSelector(state => state.exp.future.length > 0);

    const [api, contextHolder] = useNotification();
    const openNotification = (title, message, type) => {
        api.open({
            type: type,
            message: title,
            description: <div>{message}</div>,
            placement: 'bottomRight'
        });
    };

    useEffect(() => {
        if (id) {
            dispatch(setExpById(id));
            dispatch(ActionCreators.clearHistory())
        }
        if (!id) {
            dispatch(setInitial());
            dispatch(ActionCreators.clearHistory())
        }
    }, [id, dispatch]);


    useEffect(() => {
        const handleUndoRedo = e => {

            const isUndo = (e.key === 'z' && (e.metaKey || e.ctrlKey));
            const isRedo = (e.key === 'y' && (e.metaKey || e.ctrlKey));

            // const isUndo = (e.ctrlKey && e.keyCode === 90 && !e.shiftKey && !e.altKey && !e.metaKey);
            // const isRedo = ((e.ctrlKey && e.keyCode === 90 && e.shiftKey && !e.altKey && !e.metaKey) ||
            //     (e.ctrlKey && e.keyCode === 89 && !e.shiftKey && !e.altKey && !e.metaKey));

            if (isUndo) {
                e.preventDefault();
                if (canUndo) {
                    dispatch(ActionCreators.undo());
                }
            }

            if (isRedo) {
                e.preventDefault();
                if (canRedo) {
                    dispatch(ActionCreators.redo());
                }
            }
        };

        window.addEventListener('keydown', handleUndoRedo);

        return () => {
            window.removeEventListener('keydown', handleUndoRedo);
        };
    }, [canUndo, canRedo, dispatch]);

    const [s3Locations, setS3Locations] = useState([]);
    useEffect(() => {
        async function fetchData() {
            var data = await getAllS3Locations();
            setS3Locations(data);
        }
        fetchData();
    }, []);

    const [allColumns, setAllColumns] = useState();
    useEffect(() => {
        async function fetchData() {
            var columns = await getColumns(exp?.type);
            setAllColumns(columns);

            if (!id) {
                dispatch(updateColumns(
                    columns
                        .filter(c => !c.hiddenByDefault)
                        .map(c => {
                            return {
                                propertyPath: c.propertyPath,
                                name: c.name,
                                type: c.filteringType
                            }
                        })
                ))
                dispatch(updateDestination({
                    ...exp.destination,
                    "format": "CSV",
                    "type": "Email"
                }))
            }
        }
        if (exp?.type) {
            fetchData();
        }
    }, [exp?.type]);

    const panelStyle = {
        overflowX: 'scroll',
        paddingBottom: "0px",
        background: "#e6f7ff",
        height: "calc(100vh - 68px)",
    };

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                var data = await getExportPreview(exp);
                setData(data);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                openNotification('Error', 'Something gone wrong', 'error');
            }
        }
        if (exp?.type && exp?.columns?.length !== 0) {
            fetchData();
        }
        else {
            setData([]);
        }
    }, [exp?.columns, exp?.filters, exp?.sorters]);

    const handleTableOnChange = (_, newFilters, sort) => {
        const mapped = Object.entries(newFilters).filter(([field, value]) => Array.isArray(value) && value.length > 0).map(([field, value]) => ({ field, ...value[0] }));

        let filtersToSet = mapped.map(m => {
            let column = allColumns.find(c => c.propertyPath === m.field);
            return { ...m, type: column.filteringType };
        })
        dispatch(updateFilters(filtersToSet));


        if (!Array.isArray(sort)) {
            let newSort = [sort].filter(c => c.order).map(s => {
                let column = allColumns.find(c => c.propertyPath === s.columnKey);
                return {
                    columnKey: column.propertyPath,
                    order: s.order,
                    type: column.filteringType,
                }

            });
            dispatch(updateSorters(newSort))
        }
        else {
            let newSort = sort.filter(c => c.order).map(s => {
                let column = allColumns.find(c => c.propertyPath === s.columnKey);

                return {
                    columnKey: column.propertyPath,
                    order: s.order,
                    type: column.filteringType,
                }

            });
            dispatch(updateSorters(newSort))
        }
    };

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const newColumns = [...exp.columns];
            const item = newColumns.splice(fromIndex, 1)[0];
            newColumns.splice(toIndex, 0, item);
            dispatch(updateColumns(newColumns));
        },
        nodeSelector: "th"
    };

    const [columnNameEditing, setColumnNameEditing] = useState({
        isEditing: false,
        propertyPath: '',
        currentValue: ''
    });

    const [selectedFtpMode, setSelectedFtpMode] = useState("ftp://")
    const selectBeforeFtp = (
        <Select defaultValue="ftp://" value={exp.destination.ftpMode} onChange={(v) => { dispatch(updateDestination({ ...exp.destination, ftpMode: v })) }}>
            <Option value="ftp://">ftp://</Option>
            <Option value="sftp://">sftp://</Option>
        </Select>
    );

    if (exp.isLoading) {

        return <Spin tip="Loading your export config" className='absolute-centered' />
    }

    return (<>
        {contextHolder}
        <Modal
            open={columnNameEditing.isEditing}
            onOk={() => {
                dispatch(updateColumns(exp.columns.map(c => c.propertyPath === columnNameEditing.propertyPath ? { ...c, name: columnNameEditing.currentValue } : c))); setColumnNameEditing({
                    isEditing: false,
                    propertyPath: '',
                    currentValue: ''
                })
            }}
            onCancel={() => setColumnNameEditing({
                isEditing: false,
                propertyPath: '',
                currentValue: ''
            })}
        >
            <Input
                style={{ marginTop: "25px", marginBottom: "10px" }}
                type="text"
                value={columnNameEditing.currentValue}
                onChange={(e) => setColumnNameEditing({ ...columnNameEditing, currentValue: e.target.value })}
            />
        </Modal>
        <div style={{ display: "flex", alignItems: 'center', marginTop: "5px", marginBottom: "10px", width: "100%" }}>
            <Popconfirm
                title="Exit this edit?"
                disabled={exp.columns?.length <= 1}
                description="No changes will be saved"
                onConfirm={(e) => {
                    e.stopPropagation();
                    dispatch(setInitial());
                    dispatch(ActionCreators.clearHistory())
                    navigate("/");
                }}
                onCancel={(e) => { e.stopPropagation() }}
                okText="Yes"
                cancelText="No"
                placement="bottom"
            >
                <Button type="primary" icon={<CloseOutlined />} style={{ width: "150px" }} onClick={(e) => { }} >Quit</Button>
            </Popconfirm>
            <div style={{ flex: 1 }}>
                <Title style={{ margin: 0 }} level={4}>{exp.currentPanel}</Title>
            </div>
            <div style={{ width: "150px" }} ></div>
        </div>

        {exp.currentPanel === "Basic Settings" &&
            <EditingStage
                showBack={false}
                forwardClick={(e) => { basicSettingsForm.submit() }}
            >
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "5px", width: "100%", height: "100%", alignItems: "center" }}>
                    <Form name="Export_name_and_type"
                        requiredMark={false}
                        autoComplete={false}
                        form={basicSettingsForm}
                        fields={[
                            {
                                "name": [
                                    "Export Name"
                                ],
                                "value": exp.name
                            }
                        ]}
                        layout="inline"
                        onFieldsChange={(_, allFields) => {
                            console.log(allFields)
                        }}
                        style={{ width: "100%", }}
                        onFinish={(values) => { console.log(values); dispatch(updatePanel("Column Settings")); }}
                        onFinishFailed={(errorInfo) => { console.log(errorInfo) }}
                    >
                        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row" }}>
                            <div>
                                <Select
                                    defaultValue="ProcessedOrders"
                                    options={
                                        [
                                            { value: 'ProcessedOrders', label: 'Processed Orders', },
                                            { value: 'OpenOrders', label: 'Open Orders', },
                                            { value: 'Refunds', label: 'Refunds', },
                                            { value: 'Returns', label: 'Returns', },
                                        ]
                                    }
                                    value={exp.type}
                                    style={{
                                        width: 150,
                                    }}
                                    onChange={(value) => dispatch(updateType(value))}
                                />
                            </div>

                            <Form.Item
                                name="Export Name"
                                initialValue={exp.name}
                                style={{
                                    flex: 1,
                                    marginLeft: 10,
                                    marginRight: 0
                                }}
                                help={<></>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Export name is required!',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Export name"
                                    onChange={(event) => dispatch(updateName(event.target.value))}
                                />
                            </Form.Item>
                        </div>
                    </Form>

                    <TextArea
                        style={{ width: "100%" }}
                        showCount
                        rows={6}
                        maxLength={256}
                        placeholder="Description"
                        value={exp.description}
                        onChange={(event) => dispatch(updateDescription(event.target.value))}
                    />

                    <div style={{ marginTop: "20px", display: "flex", alignItems: "center" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", height: "32px", overflow: "hidden" }}>
                            <Cron
                                allowedPeriods={[
                                    'year',
                                    'month',
                                    'week',
                                    'day',
                                    'hour',
                                ]}
                                allowedDropdowns={[...[
                                    'period',
                                    'months',
                                    'hours',
                                ],
                                ...(exp.cron.split(' ')[2] == "*" ? ['week-days'] : []),
                                ...(exp.cron.split(' ')[4] == "*" ? ['month-days'] : [])
                                ]}
                                value={exp.cron}
                                setValue={(v) => dispatch(updateCron(v))}
                                onError={onCronError}
                                clearButton={false}
                                humanizeLabels
                                humanizeValue
                                leadingZero
                                locale={{
                                    prefixPeriod: 'Runs every ',
                                }}
                                clockFormat="24-hour-clock"
                            />
                        </div>
                        :00
                    </div>
                </div>
            </EditingStage>
        }
        {exp.currentPanel === "Column Settings" &&
            <EditingStage
                loading={isLoading}
                backClick={(e) => { dispatch(updatePanel("Basic Settings")); }}
                forwardClick={(e) => { dispatch(updatePanel("Export Destination")); }}
            >
                <div style={{ width: "100%", display: "flex", marginTop: "5px", marginBottom: "15px", justifyContent: "flex-end" }}>
                    <Select
                        showSearch
                        value={"Add columns"}
                        placeholder="Add columns"
                        disabled={isLoading}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label ?? "")
                                .toLowerCase()
                                .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        style={{
                            width: 400
                        }}
                        onChange={(value) => {
                            let foundColumn = allColumns.find(c => c.propertyPath === value);
                            let newColumns = [{
                                propertyPath: value,
                                name: foundColumn.name,
                                type: foundColumn.filteringType
                            }, ...exp.columns];
                            dispatch(updateColumns(newColumns));
                        }}
                        options={
                            Object.entries(allColumns?.filter(c => {
                                return !exp.columns.some(ac => ac.propertyPath === c.propertyPath)
                            })?.reduce((x, y) => {

                                (x[y.infoType] = x[y.infoType] || []).push(y);

                                return x;

                            }, {}) ?? {})?.map(([field, value]) => {
                                return {
                                    label: field,
                                    options: value.map(v => {
                                        return {
                                            label: v.name,
                                            value: v.propertyPath
                                        }
                                    })
                                };
                            }) ?? []
                        }
                    />
                </div>
                <ReactDragListView.DragColumn {...dragProps} enableScroll scrollSpeed={1000} lineClassName="dragLine">
                    <Table
                        scroll={{
                            x: "max-content", y: "calc(100vh - 280px)"
                        }}
                        onChange={handleTableOnChange}
                        pagination={false}
                        dataSource={data?.length === 0 ? [{}] : data}
                        loading={{
                            spinning: isLoading,
                            tip: <div>Loading preview might take some time</div>,
                            size: "large",
                            indicator: <Spin />,
                        }}
                        bordered
                    >
                        {
                            exp.columns.map(i => {
                                let column = allColumns?.find(c => c.propertyPath === i.propertyPath);
                                return (
                                    <Column
                                        style={{ width: "300px" }}
                                        width={"300px"}
                                        minWidth={"300px"}
                                        title={
                                            <div div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", gap: "5px" }}>

                                                <Popconfirm
                                                    title="Delete the column"
                                                    disabled={exp.columns?.length <= 1}
                                                    description="Are you sure to delete this column?"
                                                    onConfirm={(e) => {
                                                        e.stopPropagation();
                                                        dispatch(removeColumn(i.propertyPath))
                                                    }}
                                                    onCancel={(e) => { e.stopPropagation() }}
                                                    okText="Yes"
                                                    cancelText="No"
                                                >
                                                    <CloseOutlined disabled={exp.columns?.length <= 1} onClick={(e) => e.stopPropagation()}></CloseOutlined>
                                                </Popconfirm>

                                                <div>{i.name}</div>
                                                <EditOutlined onClick={(e) => {
                                                    e.stopPropagation();
                                                    setColumnNameEditing({
                                                        isEditing: true,
                                                        propertyPath: i.propertyPath,
                                                        currentValue: i.name
                                                    });
                                                }} />
                                            </div>
                                        }

                                        filterDropdown={({
                                            setSelectedKeys,
                                            selectedKeys,
                                            confirm,
                                            clearFilters,
                                        }) => {
                                            return RenderFilter(column?.filteringType, i.name, setSelectedKeys, selectedKeys, confirm, clearFilters);
                                        }}
                                        dataIndex={i.name}
                                        key={i.propertyPath}
                                        showSorterTooltip={false}
                                        sorter={{ multiple: 3 }}
                                        filteredValue={[exp.filters.find(f => f.field === i.propertyPath)].filter(i => i)}
                                    >

                                    </Column>
                                );
                            }
                            )
                        }
                    </Table>
                </ReactDragListView.DragColumn >
                {data?.length === 0 && !isLoading ? <Empty className='absolute-centered'></Empty> : <></>}
            </EditingStage >
        }
        {
            exp.currentPanel === "Export Destination" &&
            <EditingStage
                backClick={(e) => { dispatch(updatePanel("Column Settings")); }}
                forwardIcon={<CheckSquareOutlined />}
                forwardText="Save"
                disabled={saveButtonDisabled}
                forwardClick={async (e) => {
                    if (!saveButtonDisabled) {
                        try {
                            setSaveButtonDisabled(true);
                            destinationForm.submit();
                            setSaveButtonDisabled(false);
                        } catch (error) {
                            setSaveButtonDisabled(false);
                        }
                    }
                }}
            >
                <div style={{ width: "100%", height: "100%", display: "flex", marginTop: "5px", marginBottom: "15px", justifyContent: "space-around", alignItems: "center" }}>
                    <div style={{ height: "250px", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Title style={{ margin: 0, marginBottom: "24px" }} level={5}>Format</Title>
                        <RenderDestinationFormat format={exp.destination.format} height="50px" width="50px" style={{ marginBottom: "10px" }} />
                        <Select
                            defaultValue={'CSV'}
                            value={exp.destination.format}
                            style={{ width: "150px" }}
                            onChange={(v) => dispatch(updateDestination({ ...exp.destination, format: v }))}
                        >
                            <Option value="CSV" label="CSV">
                            </Option>
                            <Option value="Excel" label="Excel">
                            </Option>
                            <Option value="JSON" label="JSON">
                            </Option>
                        </Select>
                    </div>
                    <RightOutlined style={{ color: "#0092E4" }} />
                    <div style={{ height: "250px", flex: 1, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <Title style={{ margin: 0, marginBottom: "24px" }} level={5}>Destination</Title>
                        <RenderDestinationType type={exp.destination.type} height="50px" width="50px" style={{ marginBottom: "10px" }} />
                        <Select
                            defaultValue={'Email'}
                            value={exp.destination.type}
                            onChange={(v) => {
                                let newDest = { ...exp.destination, format: exp.destination.format, isEncrypted: false, type: v, ftpMode: exp.destination.ftpMode ?? 'ftp://' };
                                newDest.ftpMode ??= 'ftp://';
                                dispatch(updateDestination(newDest));
                            }}
                            style={{ width: "150px" }}
                        >
                            <Option value="Email" label="Email">
                                Email
                            </Option>
                            {/* <Option value="DropBox" label="Drop Box">
                            Drop Box
                        </Option> */}
                            {/* <Option value="GoogleDrive" label="Google Drive">
                            Google Drive
                        </Option> */}
                            <Option value="Amazon" label="Amazon S3">
                                Amazon S3
                            </Option>
                            <Option value="FTP" label="FTP Server">
                                FTP Server
                            </Option>
                        </Select>
                    </div>

                    <Form name="Export_destination"
                        requiredMark={false}
                        fields={[
                            {
                                "name": [
                                    "SmtpServer"
                                ],
                                "value": exp.destination.server
                            },
                            {
                                "name": [
                                    "Port"
                                ],
                                "value": exp.destination.port
                            },
                            {
                                "name": [
                                    "Your Email"
                                ],
                                "value": exp.destination.smtpSenderEmail
                            },
                            {
                                "name": [
                                    "Your Smtp Login"
                                ],
                                "value": exp.destination.smtpSenderLogin
                            },
                            {
                                "name": [
                                    "Your Password"
                                ],
                                "value": exp.destination.password
                            },
                            {
                                "name": [
                                    "Recipient Email"
                                ],
                                "value": exp.destination.smtpRecipientEmail
                            },
                            {
                                "name": [
                                    "Bucket Region"
                                ],
                                "value": exp.destination.s3Region
                            },
                            {
                                "name": [
                                    "Bucket Name"
                                ],
                                "value": exp.destination.s3Bucket
                            },
                            {
                                "name": [
                                    "Access Key"
                                ],
                                "value": exp.destination.s3AccessKey
                            },
                            {
                                "name": [
                                    "Secret Key"
                                ],
                                "value": exp.destination.s3SecretKey
                            },
                            {
                                "name": [
                                    "Server"
                                ],
                                "value": exp.destination.server
                            },
                            {
                                "name": [
                                    "User Name"
                                ],
                                "value": exp.destination.ftpUserName
                            },
                            {
                                "name": [
                                    "User Password"
                                ],
                                "value": exp.destination.password
                            },
                        ]}
                        autoComplete={false}
                        layout="horizontal"
                        form={destinationForm}
                        onFieldsChange={(_, allFields) => {
                            console.log(allFields)
                        }}
                        onFinish={async (values) => {
                            console.log("DONE")
                            await saveExport(exp);
                            dispatch(setInitial());
                            dispatch(ActionCreators.clearHistory())
                            navigate("/");
                        }}
                        onFinishFailed={(errorInfo) => { console.log(errorInfo) }} style={{ flex: 5 }}>
                        <Title style={{ margin: 0, marginBottom: "24px" }} level={4}>Credentials</Title>
                        {
                            exp.destination.type === "Email" &&
                            <>
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "flex-start", gap: "8px" }}>
                                    <Form.Item
                                        name="SmtpServer"
                                        help={<></>}
                                        initialValue={exp.destination.server}
                                        style={{ flex: 1 }}
                                        value
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Smtp Server is required!',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<CloudServerOutlined />} placeholder="Smtp Server" value={exp.destination.server} onChange={(e) => dispatch(updateDestination({ ...exp.destination, server: e.target.value }))} size="large"></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="Port"
                                        initialValue={exp.destination.port}
                                        value
                                        help={<></>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Port is required!',
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder="Port" value={exp.destination.port} onChange={(e) => dispatch(updateDestination({ ...exp.destination, port: e }))} size="large"></InputNumber>
                                    </Form.Item>
                                    <Switch checkedChildren="SSL" unCheckedChildren="SSL" style={{ marginTop: "10px" }} checked={exp.destination.enableSsl} onChange={(e) => dispatch(updateDestination({ ...exp.destination, enableSsl: e }))} size="large"></Switch>
                                </div>
                                <Form.Item
                                    name="Your Email"
                                    initialValue={exp.destination.smtpSenderEmail}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Your Email is required!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder="Your Email" value={exp.destination.smtpSenderEmail} onChange={(e) => dispatch(updateDestination({ ...exp.destination, smtpSenderEmail: e.target.value }))} size="large"></Input>
                                </Form.Item>
                                <Form.Item
                                    name="Your Smtp Login"
                                    initialValue={exp.destination.smtpSenderLogin}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Your Smtp Login is required!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<LoginOutlined />} placeholder="Your Smtp Login" value={exp.destination.smtpSenderLogin} onChange={(e) => dispatch(updateDestination({ ...exp.destination, smtpSenderLogin: e.target.value }))} size="large"></Input>
                                </Form.Item>
                                <Form.Item
                                    name="Your Password"
                                    initialValue={exp.destination.password}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Your Password is required!',
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} placeholder="Your Password" value={exp.destination.password} onChange={(e) => dispatch(updateDestination({ ...exp.destination, isEncrypted: false, password: e.target.value }))} size="large"></Input.Password>
                                </Form.Item>
                                <Form.Item
                                    name="Recipient Email"
                                    initialValue={exp.destination.smtpRecipientEmail}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Recipient Email is required!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder="Recipient Email" value={exp.destination.smtpRecipientEmail} onChange={(e) => dispatch(updateDestination({ ...exp.destination, smtpRecipientEmail: e.target.value }))} size="large"></Input>
                                </Form.Item>
                            </>
                        }
                        {
                            exp.destination.type === "DropBox" &&
                            <>
                                DropBox
                            </>
                        }
                        {
                            exp.destination.type === "GoogleDrive" &&
                            <>GoogleDrive</>
                        }
                        {
                            exp.destination.type === "Amazon" &&
                            <>
                                <Form.Item
                                    name="Bucket Region"
                                    initialValue={exp.destination.s3Region}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Bucket Region is required!',
                                        },
                                    ]}
                                >
                                    <CustomSelect
                                        prefixIcon={<CloudServerOutlined />}
                                        placeholder="Bucket Region"
                                        size="large"
                                        value={exp.destination.s3Region}
                                        onChange={(v) => {
                                            dispatch(updateDestination({ ...exp.destination, s3Region: v }));
                                        }}
                                    >
                                        {s3Locations.map(l =>
                                            <Option value={l} label={l}>
                                                {l}
                                            </Option>)
                                        }
                                    </CustomSelect>
                                </Form.Item>
                                <Form.Item
                                    name="Bucket Name"
                                    initialValue={exp.destination.s3Bucket}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Bucket Name is required!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<CloudServerOutlined />} placeholder="Bucket Name" value={exp.destination.s3Bucket} onChange={(e) => dispatch(updateDestination({ ...exp.destination, s3Bucket: e.target.value }))} size="large"></Input>
                                </Form.Item>

                                <Input prefix={<FolderOpenOutlined />} style={{ marginBottom: "24px" }} placeholder="Folder Name" value={exp.destination.folder} onChange={(e) => dispatch(updateDestination({ ...exp.destination, folder: e.target.value }))} size="large"></Input>

                                <Form.Item
                                    name="Access Key"
                                    initialValue={exp.destination.s3AccessKey}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Access Key is required!',
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<KeyOutlined />} placeholder="Access Key" value={exp.destination.s3AccessKey} onChange={(e) => dispatch(updateDestination({ ...exp.destination, isEncrypted: false, s3AccessKey: e.target.value }))} size="large"></Input.Password>
                                </Form.Item>

                                <Form.Item
                                    name="Secret Key"
                                    initialValue={exp.destination.s3SecretKey}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Secret Key is required!',
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} placeholder="Secret Key" value={exp.destination.s3SecretKey} onChange={(e) => dispatch(updateDestination({ ...exp.destination, isEncrypted: false, s3SecretKey: e.target.value }))} size="large"></Input.Password>
                                </Form.Item>
                            </>
                        }
                        {
                            exp.destination.type === "FTP" &&
                            <>
                                <div style={{ display: "flex", justifyContent: "space-around", alignItems: "flex-start", gap: "8px" }}>
                                    <Form.Item
                                        style={{ flex: 1 }}
                                        name="Server"
                                        initialValue={exp.destination.server}
                                        value
                                        help={<></>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Server is required!',
                                            },
                                        ]}
                                    >
                                        <Input prefix={<CloudServerOutlined />} addonBefore={selectBeforeFtp} placeholder="Server" value={exp.destination.server} onChange={(e) => dispatch(updateDestination({ ...exp.destination, server: e.target.value }))} size="large"></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="Port"
                                        initialValue={exp.destination.port}
                                        value
                                        help={<></>}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Port is required!',
                                            },
                                        ]}
                                    >
                                        <InputNumber placeholder="Port" value={exp.destination.port} onChange={(e) => dispatch(updateDestination({ ...exp.destination, port: e }))} size="large"></InputNumber>
                                    </Form.Item>
                                    {/* <Switch checkedChildren="SSL" unCheckedChildren="SSL" style={{ marginTop: "10px" }} checked={exp.destination.enableSsl} onChange={(e) => dispatch(updateDestination({ ...exp.destination, enableSsl: e }))} size="large"></Switch> */}
                                </div>
                                <Form.Item
                                    name="User Name"
                                    initialValue={exp.destination.ftpUserName}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User Name is required!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined />} placeholder="User Name" value={exp.destination.ftpUserName} onChange={(e) => dispatch(updateDestination({ ...exp.destination, ftpUserName: e.target.value }))} size="large"></Input>
                                </Form.Item>
                                <Form.Item
                                    name="User Password"
                                    initialValue={exp.destination.ftpUserName}
                                    value
                                    help={<></>}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'User Password is required!',
                                        },
                                    ]}
                                >
                                    <Input.Password prefix={<LockOutlined />} placeholder="Password" value={exp.destination.password} onChange={(e) => dispatch(updateDestination({ ...exp.destination, isEncrypted: false, password: e.target.value }))} size="large"></Input.Password>
                                </Form.Item>

                                <Input prefix={<FolderOpenOutlined />} placeholder="Folder Path" value={exp.destination.folder} onChange={(e) => dispatch(updateDestination({ ...exp.destination, folder: e.target.value }))} size="large"></Input>
                            </>
                        }
                    </Form>

                </div>
            </EditingStage>
        }

    </>);
}

export default Create;