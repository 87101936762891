/* eslint-disable react/prop-types */
import { Button, Input, Divider, Switch } from 'antd';
import React from "react";


const BoolFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {

    return (<div style={{ padding: "4px" }}>
        <div style={{ width: "100%", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
            <Switch
                checkedChildren="Should be true" unCheckedChildren="Should be false"
                checked={selectedKeys[0]?.boolFilter}
                onChange={(value) => {
                    setSelectedKeys([{ ...selectedKeys[0], boolFilter: value }]);
                }}
            ></Switch>
        </div>

        <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
        <Button
            onClick={() => {
                let finalValue = selectedKeys[0]?.boolFilter ? selectedKeys[0]?.boolFilter : false;
                setSelectedKeys([{ ...selectedKeys[0], boolFilter: finalValue }]);
                confirm();
            }}
            type="primary"
        >
            Search
        </Button>
        <Button
            onClick={() => {
                clearFilters();
                confirm();
            }}
            type="danger"
        >
            Reset
        </Button>
    </div >);
}

export default BoolFilter;