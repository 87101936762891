/* eslint-disable react/prop-types */
import { DatePicker, Button, Divider, Select, InputNumber } from 'antd';
import React from "react";
import dayjs from 'dayjs';


const DateFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
    return (
        <>
            <div style={{ padding: "4px", width: 380 }}>
                <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <Select
                        defaultValue="Range"
                        value={selectedKeys[0]?.dateTimeColumnType ?? 'Range'}
                        style={{ flex: 2 }}
                        onChange={(v) => { setSelectedKeys([{ ...selectedKeys[0], dateTimeColumnType: v }]) }}
                        options={[
                            { value: 'Range', label: 'Range' },
                            { value: 'FromLast', label: 'From Last Export' },
                            { value: 'Constant', label: 'Last Hours' },
                        ]}
                    />
                    {
                        (selectedKeys[0]?.dateTimeColumnType === 'Range' || !selectedKeys[0]?.dateTimeColumnType) &&
                        <DatePicker.RangePicker
                            style={{ flex: 3 }}
                            format={"MM/DD/YYYY"}
                            value={[dayjs(selectedKeys[0]?.from ?? dayjs()), dayjs(selectedKeys[0]?.to ?? dayjs())]}
                            onChange={e => setSelectedKeys(Array.isArray(e) ? [{ ...selectedKeys[0], from: e[0]?.toJSON(), to: e[1]?.toJSON(), dateTimeColumnType: 'Range' }] : [{ ...selectedKeys[0], from: null, to: null, dateTimeColumnType: 'Range' }])}
                            onPressEnter={() => {
                                confirm();
                            }}
                        />
                    }
                    {
                        selectedKeys[0]?.dateTimeColumnType === 'Constant' &&
                        <InputNumber
                            min={1}
                            autoFocus
                            style={{ flex: 3 }}
                            value={selectedKeys[0]?.hours}
                            placeholder={'Hours'}
                            onChange={e => setSelectedKeys([{ ...selectedKeys[0], dateTimeColumnType: 'Constant', hours: e }])}
                            onPressEnter={() => {
                                confirm();
                            }}
                        ></InputNumber>
                    }
                </div>

                <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
                <Button
                    disabled={(
                        (!selectedKeys[0]?.from || !selectedKeys[0]?.to) && (selectedKeys[0]?.dateTimeColumnType === 'Range' || !selectedKeys[0]?.dateTimeColumnType)
                        ||
                        (selectedKeys[0]?.dateTimeColumnType === 'Constant' && (!selectedKeys[0]?.hours || selectedKeys[0]?.hours <= 0))
                    )}
                    onClick={() => {
                        confirm();
                    }}
                    type="primary"
                >
                    Search
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                    }}
                    type="danger"
                >
                    Reset
                </Button>
            </div>
        </>
    );
}

export default DateFilter;