import { deleteExportById, getAllExports, getColumnsByType, getExportById, previewExport, runExportById, s3Locations, save } from './endpoints'
import axios from 'axios';

export const getExports = async () => {
    var res = await axios.get(getAllExports);
    return res.data;
}
export const getExport = async (id) => {
    var res = await axios.get(getExportById(id));
    return res.data;
}
export const getExportPreview = async (exp) => {
    var res = await axios.post(previewExport, exp);
    return res.data;
}
export const saveExport = async (exp) => {
    var res = await axios.post(save, exp);
    return res.data;
}
export const runExport = async (id, affectTuntime) => {
    var res = await axios.post(runExportById(id, affectTuntime));
    return res.data;
}

export const deleteExport = async (exp) => {
    var res = await axios.post(deleteExportById(exp.id));
    return res.data;
}

export const getColumns = async (type) => {
    var res = await axios.get(getColumnsByType(type));
    return res.data;
}
export const getAllS3Locations = async () => {
    var res = await axios.get(s3Locations);
    return res.data;
}