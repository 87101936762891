import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button } from 'antd';


const EditingStage = ({ children, showBack = true, showForward = true, backClick, forwardClick, backText = "Back", forwardText = "Next", backIcon, forwardIcon, loading }) => {
    const panelStyle = {
        overflowX: 'scroll',
        paddingBottom: "0px",
        height: "calc(100vh - 68px)",
    };
    return (
        <div style={panelStyle}>
            <div style={{ width: "95%", height: "calc(100vh - 86px)", marginLeft: "auto", marginRight: "auto", display: "flex", flexDirection: "column" }}>

                <div style={{ flex: 1, padding: "10px" }}>
                    {children}
                </div>

                <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", paddingBottom: "10px", paddingTop: "10px" }}>
                    {showBack && <Button type="primary" disabled={loading} icon={backIcon ?? <LeftOutlined />} style={{ width: "150px" }} onClick={backClick} >{backText}</Button>}
                    <div style={{ flex: 1 }}></div>
                    {showForward && <Button type="primary" disabled={loading} icon={forwardIcon ?? <RightOutlined />} style={{ width: "150px" }} onClick={forwardClick} >{forwardText}</Button>}
                </div>
            </div>

        </div >
    );
}

export default EditingStage;