/* eslint-disable react/prop-types */
import { Button, Divider, Select, InputNumber } from 'antd';
import React from 'react';
const { Option } = Select;


const IntegerFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, placeholder }) => {
    return (
        <div style={{ padding: "4px" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <Select defaultValue=">" style={{ width: 120 }} value={selectedKeys[0]?.sign} onChange={e => {
                    setSelectedKeys([{ ...selectedKeys[0], sign: e, number: selectedKeys[0]?.number ? selectedKeys[0].number : null }])
                }}>
                    <Option value=">">{'>'}</Option>
                    <Option value="<">{'<'}</Option>
                    <Option value="=">{'='}</Option>
                </Select>
                <InputNumber
                    min={1}
                    autoFocus
                    value={selectedKeys[0]?.number}
                    placeholder={placeholder}
                    onChange={(e) => {
                        setSelectedKeys(e ? [{ ...selectedKeys[0], number: e, sign: selectedKeys[0]?.sign ? selectedKeys[0].sign : '>' }] : [{ ...selectedKeys[0], number: null, sign: selectedKeys[0]?.sign ? selectedKeys[0].sign : '>' }]);
                    }}
                    onPressEnter={() => {
                        confirm();
                    }}
                ></InputNumber>
            </div>
            <Divider style={{ marginTop: '4px', marginBottom: '4px' }} />
            <Button
                onClick={() => {
                    confirm();
                }}
                type="primary"
            >
                Search
            </Button>
            <Button
                onClick={() => {
                    clearFilters();
                    confirm();
                }}
                type="danger"
            >
                Reset
            </Button>
        </div>
    );
}

export default IntegerFilter;